import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import NavTabs from './NavTabs';
import './App.css';

function App(props) {
  return (
    <React.Fragment>
      <CssBaseline enableColorScheme />
      <div className="app">
        <header className="app-main">
          <Typography variant="h1" fontWeight="bold" pt={3}>Project Iguana</Typography>
          <Typography variant="h3" pb={3}>The Case of the Golden Relic</Typography>
          <NavTabs {...props} />
        </header>
      </div>
    </React.Fragment>
  );
}

export default App;
