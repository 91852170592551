import React from 'react';

export default function Video(props) {
  const preload = props.preload ? 'auto' : 'metadata';
  return (
    <video width={props.width} height={props.height} poster={props.thumbnail} preload={preload} controls>
      <source src={props.source} type="video/mp4" />
      Your browser does not support the video tag.
    </video>
  );
}
