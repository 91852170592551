import React from 'react';
import Video from './Video';

export default function FeatureFilm() {
  return (
    <Video width="320" height="240" preload
           thumbnail="/images/project_iguana_feature_film.png"
           source="/video/project_iguana_feature_film_web.mp4" />
  );
}
