import React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

function getCopyrightYears(startYear) {
  const currentYear = new Date().getFullYear();
  if (startYear === currentYear) {
    return currentYear;
  }
  return <React.Fragment>{startYear} &ndash; {currentYear}</React.Fragment>;
}

export default function About() {
  return (
    <Container maxWidth="lg">
      <Typography>
        <Typography variant="span" fontStyle="italic">Project Iguana</Typography> was created in 2002 by an ambitious
        (and bored) group of amateur filmmakers, who also happen to be cousins.
      </Typography>

      <Typography mt={3}>
        Its legacy is preserved on this website, mostly for that same group of amateur filmmakers to revisit from time
        to time with a chuckle.
      </Typography>
      <Typography>
        If you have somehow stumbled upon this website without prior knowledge of the film, we...hope you enjoy it...?
      </Typography>

      <Typography mt={3}>
        Feature film copyright &copy; {getCopyrightYears(2002)}. All rights reserved.
      </Typography>
      <Typography>
        Behind the scenes videos copyright &copy; {getCopyrightYears(2021)}. All rights reserved.
      </Typography>
      <Typography>
        Use of this material for any purpose without the express written consent of the authors is strictly prohibited.
      </Typography>
    </Container>
  );
}
