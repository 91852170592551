import React from 'react';
import Container from '@mui/material/Container';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import Video from './Video';

const behindTheScenesVideos = [
  {
    source: '/video/project_iguana_behind_the_scenes_web.mp4',
    thumbnail: '/images/project_iguana_behind_the_scenes.png',
    title: 'Part 1 Gag Reel',
  },
  {
    source: '/video/project_iguana_2_behind_the_scenes_web.mp4',
    thumbnail: '/images/project_iguana_2_behind_the_scenes.png',
    title: 'Part 2 Gag Reel',
  },
  {
    source: '/video/project_iguana_footage_web.mp4',
    thumbnail: '/images/project_iguana_footage.png',
    title: 'Part 1 Original Footage',
  },
  {
    source: '/video/project_iguana_2_footage_web.mp4',
    thumbnail: '/images/project_iguana_2_footage.png',
    title: 'Part 2 Original Footage',
  },
];

export default function BehindTheScenesVideoList() {
  return (
    <Container sx={{ display: 'flex', justifyContent: 'center' }}>
      <ImageList sx={{ marginTop: 0, width: 700 }}>
        {behindTheScenesVideos.map((video) => (
          <ImageListItem key={video.source} sx={{ width: 320 }}>
            <Video width="320" height="175" thumbnail={video.thumbnail} source={video.source} />
            <ImageListItemBar title={video.title} position="below" sx={{ fontWeight: 'bold', paddingBottom: 3 }} />
          </ImageListItem>
        ))}
      </ImageList>
    </Container>
  );
}
