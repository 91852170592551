import { getAnalytics } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import App from './components/App';

const firebaseConfig = {
  apiKey: 'AIzaSyBJ3LzrvueiS3t5xAls3j4duOIT64XG6gY',
  authDomain: 'project-iguana.firebaseapp.com',
  projectId: 'project-iguana',
  storageBucket: 'project-iguana.appspot.com',
  messagingSenderId: '261677753980',
  appId: '1:261677753980:web:9ebd8e573b730a3a76cbf9',
  measurementId: 'G-0GZYDDSXXH',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

ReactDOM.render(
  <React.StrictMode>
    <App analytics={analytics} />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
