import { logEvent } from 'firebase/analytics';
import React from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import About from './About';
import BehindTheScenesVideoList from './BehindTheScenesVideoList';
import FeatureFilm from './FeatureFilm';
import TabPanel from './TabPanel';

const analyticsEventValues = ['feature_film', 'behind_the_scenes', 'about'];

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function NavTabs(props) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    logEvent(props.analytics, 'select_content', { content_type: analyticsEventValues[newValue] });
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" centered>
          <Tab label="Feature Film" sx={{ color: 'white' }} {...a11yProps(0)} />
          <Tab label="Behind the Scenes" sx={{ color: 'white' }} {...a11yProps(1)} />
          <Tab label="About" sx={{ color: 'white' }} {...a11yProps(2)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <FeatureFilm />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <BehindTheScenesVideoList />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <About />
      </TabPanel>
    </Box>
  );
}
